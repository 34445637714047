import React, { useEffect, useState } from 'react';
import LoadingScreen from 'react-loading-screen';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Slider from 'react-slick';
import mergeImages from 'mindsoft-merge-images';
import { faAlignCenter } from '@fortawesome/free-solid-svg-icons';

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 2,
  slidesToScroll: 1,
  vertical: true,
  verticalSwiping: true,
  swipeToSlide: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

const url = process.env.REACT_APP_MAIN_URL_API;
const colorsEndPoint = process.env.REACT_APP_PALETA_DE_COLORES_API;
const ambientesEndPoint = process.env.REACT_APP_AMBIENTES_API;
const username = process.env.REACT_APP_API_USERNAME;
const password = "Editor2017$.";
const basicAuthHeader = 'Basic ' + btoa(username + ':' + password);


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      src='./down-arrow.png'
      alt='next'
      className={className}
      style={{
        ...style,
        display: 'block',
        position: 'relative',
        margin: 'auto',
        height: 'auto',
        width: 30,
        bottom: 15,
        zIndex: 1,
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      src='./up-arrow.png'
      alt='prev'
      className={className}
      style={{
        ...style,
        display: 'block',
        position: 'relative',
        margin: 'auto',
        height: 'auto',
        width: 30,
        top: 45,
        zIndex: 1,
      }}
      onClick={onClick}
    />
  );
}

export default function App() {
  const [windowDimensions, setWindowDimensions] = useState('');
  const [colors, setColors] = useState('');
  const [ambientes, setAmbientes] = useState('');
  const [actualImg, setActualImg] = useState('');
  const [actualImgIndex, setActualImgIndex] = useState(0);
  const [points, setPoints] = useState('');
  const [categorySelected, setCategorySelected] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingTxt, setLoadingTxt] = useState('Cargando Simulador...');
  const [pointSelected, setPointSelected] = useState('');
  const [commonCategories, setCommonCategories] = useState('block');
  const [prop, setProp] = useState('none');
  const { width, height } = useWindowDimensions();

  function getColors() {
    fetch(`${url}${colorsEndPoint}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': basicAuthHeader
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setColors(data.paletas);
      })
      .catch((err) => console.error(err));
  }

  function getAmbientes() {
    fetch(`${url}${ambientesEndPoint}`,  {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': basicAuthHeader,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let parsed = Object.entries(data.ambientes);
        setAmbientes(data.ambientes);
        setActualImg(parsed[0][1]['img']);
        setActualImgIndex(0);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }

  function HandleTabsChange(event, i) {
    let parsed = Object.entries(ambientes);
    setActualImg(parsed[i][1]['img']);
    setActualImgIndex(i);
    setPointSelected('');
    setPoints('');
  }

  function HandlePoints(event, obj, i) {
    setPoints(obj);
    setPointSelected(i);
    if (obj[1].prop === '1') {
      setProp('block');
      setCommonCategories('none');
    } else {
      setProp('none');
      setCommonCategories('block');
    }
  }

  function HandleCategory(event, category) {
    setCategorySelected(category);
  }

  async function HandleColor(color) {
    if (points[1] === undefined) {
      alert('Seleccione un punto para cambiar su color!');
    } else {
      if (points[1][`${categorySelected}`] !== undefined) {
        if (points[1][`${categorySelected}`][`${color}`] !== undefined) {
          const texture = points[1][`${categorySelected}`][`${color}`].img;
          try {
            mergeImages([actualImg, texture]).then((b64) => {
              setActualImg(b64);
            });
          } catch (err) {
            console.error(`${err}`);
          }
        } else {
          alert(
            'El color seleccionado no existe en la categoria actual del API!'
          );
        }
      } else {
        alert('La categoria seleccionada no existe en el API!');
      }
    }
  }

  // For mobile detection
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    setWindowDimensions({ width, height });
  }

  useEffect(() => {
    getWindowDimensions();
    getColors();
    getAmbientes();
  }, [ ]);

  function useWindowDimensions() {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);

    const updateWidthAndHeight = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    React.useEffect(() => {
      window.addEventListener('resize', updateWidthAndHeight);
      return () => window.removeEventListener('resize', updateWidthAndHeight);
    });

    return {
      width,
      height,
    };
  }

  return (
    <div className='main-container'>
      {(windowDimensions.width <= 375 && height < width) ||
      windowDimensions.width >= 768 ? (
        <LoadingScreen
          loading={loading}
          bgColor='black'
          spinnerColor='#f05c17'
          textColor='white'
          logoSrc='./logo-white.png'
          text={loadingTxt}
        >
          <Container fluid>
            <Row className='center-row'>
              <Col sm={2} className='scrolled'>
                <div
                  style={{
                    backgroundImage: 'url("/dot-pattern.png")',
                    backgroundRepeat: 'no-repeat',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 15,
                  }}
                >
                  <img src='./design-icon.png' alt='design' width={30} />
                  <h3>DISEÑOS</h3>
                </div>
                <Accordion defaultActiveKey='0'>
                  {Object.entries(colors).map(
                    (el, i) =>
                      el[0] !== 'Chapas de Madera' &&
                      el[0] !== 'Ultramates' &&
                      el[0] !== 'Texturas' &&
                      (el[0] !== 'Extras' ? (
                        <Card
                          style={{
                            borderRadius: 0,
                            display: commonCategories,
                          }}
                          id={el[0]}
                        >
                          <Card.Header
                            style={{ padding: 0, textAlign: 'center' }}
                            className={
                              categorySelected === el[0] && 'is-acc-active'
                            }
                          >
                            <Accordion.Toggle
                              as={Button}
                              variant='link'
                              eventKey={i}
                              onClick={() => HandleCategory(this, el[0])}
                            >
                              {el[0]}
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={i}>
                            <Card.Body style={{ padding: 0 }}>
                              <Slider {...settings}>
                                {Object.entries(el[1]).map(
                                  (y) =>
                                    y[0] !== 'Roble Vintage' &&
                                    y[0] !== 'Aluminio' &&
                                    y[0] !== 'Blanco' && (
                                      <Image
                                        onClick={() => HandleColor(y[0])}
                                        fluid
                                        alt='abc'
                                        src={y[1]['img']}
                                      />
                                    )
                                )}
                              </Slider>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      ) : (
                        <Card
                          style={{ borderRadius: 0, display: prop }}
                          id={el[0]}
                        >
                          <Card.Header
                            style={{ padding: 0, textAlign: 'center' }}
                            className={
                              categorySelected === el[0] && 'is-acc-active'
                            }
                          >
                            <Accordion.Toggle
                              as={Button}
                              variant='link'
                              eventKey={i}
                              onClick={() => HandleCategory(this, el[0])}
                            >
                              {el[0]}
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={i}>
                            <Card.Body style={{ padding: 0 }}>
                              <Slider {...settings}>
                                {Object.entries(el[1]).map(
                                  (y) =>
                                    y[0] !== 'Roble Vintage' && (
                                      <Image
                                        onClick={() => HandleColor(y[0])}
                                        fluid
                                        alt='abc'
                                        src={y[1]['img']}
                                      />
                                    )
                                )}
                              </Slider>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      ))
                  )}
                </Accordion>
              </Col>
              <Col sm={10} lg={8}>
                <Row>
                  {Object.entries(ambientes).map((el, i) => (
                    <Col key={i} onClick={() => HandleTabsChange(this, i)}>
                      <Card.Header
                        id={i}
                        className={actualImgIndex === i && 'is-active'}
                        style={{
                          textAlign: 'center',
                          borderRadius: 0,
                        }}
                      >
                        {el[0]}
                      </Card.Header>
                    </Col>
                  ))}
                  <Col sm={12}>
                    <div className='interactive-img'>
                      <Image
                        className='w-100'
                        fluid
                        alt='abc'
                        src={actualImg}
                      />
                      {Object.entries(ambientes)[actualImgIndex] !== undefined
                        ? Object.entries(
                            Object.entries(ambientes)[actualImgIndex][1].muebles
                          ).map((el, i) =>
                            el[0] !== 'TODOS LOS MUEBLES' ? (
                              <img
                                className='point-icon'
                                src={
                                  pointSelected === i
                                    ? './icon-hover.png'
                                    : './icon-normal.png'
                                }
                                alt='point'
                                onClick={() => HandlePoints(this, el, i)}
                                style={{
                                  position: 'absolute',
                                  top: `${el[1].x}%`,
                                  left: `${el[1].y}%`,
                                }}
                              />
                            ) : null
                          )
                        : null}
                    </div>
                  </Col>
                  <Col sm={12} >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#f05c17',
                        padding: 10,
                        flexWrap:'wrap',
                      }
                    }
                    >
                      <h4 style={{ color: 'white', fontWeight: 400 }}>
                        Comparte tus ideas!
                      </h4>
                      <a download href={actualImg}>
                        <img
                          src='./save.png'
                          alt='save'
                          width={50}
                          style={{ marginLeft: 10, marginRight: 10 }}
                        />
                      </a>
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href='https://www.facebook.com/aglomerados.cotopaxi/'
                      >
                        <img
                          src='./facebook.png'
                          alt='save'
                          width={50}
                          style={{ marginLeft: 10, marginRight: 10 }}
                        />
                      </a>
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href='https://www.instagram.com/aglomeradoscotopaxi/?hl=es-la'
                      >
                        <img
                          src='./instagram.png'
                          alt='save'
                          width={50}
                          style={{ marginLeft: 10, marginRight: 10 }}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </LoadingScreen>
      ) : (
        <div className='mobile-container'>
          <h5>Gira el dispositivo en modo horizontal</h5>
          <img src='./rotate.gif' width={165} />
        </div>
      )}
    </div>
  );
}
